import React from "react"
import { injectIntl, Link } from "gatsby-plugin-intl"

// Elements
import Heading from "./heading"
import CardTall from "./card_tall"

const hospitality = ({ intl }) => {
  return (
    <div className="hospitality__wrapper">
      <Heading
        title={intl.formatMessage({ id: "hospitality_heading" })}
        description={intl.formatMessage({ id: "hospitality_description" })}
      />
      <div className="hospitality">
        <CardTall
          id="1"
          title={intl.formatMessage({ id: "hospCard1_title" })}
          subtitle={intl.formatMessage({ id: "hospCard1_subtitle" })}
          description={intl.formatMessage({ id: "hospCard1_description" })}
          content={<Link to="/restaurant">Learn More</Link>}
        />
        <CardTall
          id="2"
          title={intl.formatMessage({ id: "hospCard2_title" })}
          subtitle={intl.formatMessage({ id: "hospCard2_subtitle" })}
          description={intl.formatMessage({ id: "hospCard2_description" })}
          content={<Link to="/bar">Learn More</Link>}
        />
        <CardTall
          id="3"
          title={intl.formatMessage({ id: "hospCard3_title" })}
          subtitle={intl.formatMessage({ id: "hospCard3_subtitle" })}
          description={intl.formatMessage({ id: "hospCard3_description" })}
          content={<Link to="/events">Learn More</Link>}
        />
      </div>
    </div>
  )
}

export default injectIntl(hospitality)
