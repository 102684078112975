import React from "react"
import SEO from "../elements/seo"
import Layout from "../elements/layout"
import { injectIntl } from "gatsby-plugin-intl"

// Components
import IndexPage from "../components/IndexPage"

const Index = ({ intl }) => (
  <Layout>
    <SEO
      title={intl.formatMessage({ id: "home" })}
      keywords={[
        `${intl.formatMessage({ id: "title" })}`,
        `${intl.formatMessage({ id: "seo" })}`,
      ]}
      description='New Macau Casino is located in Tete, catering to gaming patrons from across the globe. We feature a bar, restaurant and amazing entertainment!'
    />
    <IndexPage />
  </Layout>
)

export default injectIntl(Index)
