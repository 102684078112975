import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import { graphql, useStaticQuery } from "gatsby"

// Elements
import Slider from "../elements/slider"

// import Events from "../elements/events"
import Games from "../elements/games"
import Slots from "../elements/slots"
import Hospitality from "../elements/hospitality"
import Fortune from "../elements/fortune"
import Subscribe from "../elements/subscribe"

const IndexPage = ({ intl }) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "sliderImage1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <>
      <Slider
        sliderImage={data.file.childImageSharp.fluid}
        sliderTitle={intl.formatMessage({ id: "home_title" })}
        sliderSubtitle={intl.formatMessage({ id: "welcome" })}
      />

      <Subscribe />
      <div className="center">
        <div
          className="fb-page hideOnDesktop m-m"
          data-href="https://www.facebook.com/newmacaucasino/"
          data-tabs="timeline"
          data-width=""
          data-height=""
          data-small-header="true"
          data-adapt-container-width="false"
          data-hide-cover="false"
          data-show-facepile="true"
        >
          <blockquote
            cite="https://www.facebook.com/newmacaucasino/"
            className="fb-xfbml-parse-ignore"
          >
            <a href="https://www.facebook.com/newmacaucasino/">
              Facebook Page
            </a>
          </blockquote>
        </div>
      </div>

      {/* <Events /> */}

      <Hospitality />
      <Games />
      <Slots />
      <Fortune />
    </>
  )
}

export default injectIntl(IndexPage)
