import React from "react"
import { injectIntl } from "gatsby-plugin-intl"


// Elements
import Heading from "./heading"
import Modal from "./video_modal"

// Images
import playButton from "../images/play_button.png"

const videoLinks = [
  "PR9LPKic9IA",
  "OZuQ4EWFzN0",
  "VpsJRNkBeYE",
  "BRbO4h2OVLE",
  "gEMIRTOTtyU",
  "H-yNo34wDlU",
]

const slots = ({ intl }) => {
  return (
    <div className="slots__wrapper">
      <Heading
        title={intl.formatMessage({ id: "slots_heading" })}
        description={intl.formatMessage({ id: "slots_description" })}
      />
      <div className="slots">
        <div className="slots__image" />
        <div className="slots__item slots__item1">
          <div className="slots__modal">
            <Modal
              videoUrl={videoLinks[0]}
              title={intl.formatMessage({ id: "slot1" })}
              description={intl.formatMessage({ id: "slot1_desc" })}
              modalButton={<img src={playButton} alt="play" />}
            />
          </div>
        </div>
        <div className="slots__item slots__item2">
          <div className="slots__modal">
            <Modal
              videoUrl={videoLinks[1]}
              title={intl.formatMessage({ id: "slot2" })}
              description={intl.formatMessage({ id: "slot2_desc" })}
              modalButton={<img src={playButton} alt="play" />}
            />
          </div>
        </div>
        <div className="slots__item slots__item3">
          <div className="slots__modal">
            <Modal
              videoUrl={videoLinks[2]}
              title={intl.formatMessage({ id: "slot3" })}
              description={intl.formatMessage({ id: "slot3_desc" })}
              modalButton={<img src={playButton} alt="play" />}
            />
          </div>
        </div>
        <div className="slots__item slots__item4">
          <div className="slots__modal">
            <Modal
              videoUrl={videoLinks[3]}
              title={intl.formatMessage({ id: "slot4" })}
              description={intl.formatMessage({ id: "slot4_desc" })}
              modalButton={<img src={playButton} alt="play" />}
            />
          </div>
        </div>

        <div className="slots__item slots__item5">
          <div className="slots__modal">
            <Modal
              videoUrl={videoLinks[4]}
              title={intl.formatMessage({ id: "slot5" })}
              description={intl.formatMessage({ id: "slot5_desc" })}
              modalButton={<img src={playButton} alt="play" />}
            />
          </div>
        </div>

        <div className="slots__item slots__item6">
          <div className="slots__modal">
            <Modal
              videoUrl={videoLinks[5]}
              title={intl.formatMessage({ id: "slot6" })}
              description={intl.formatMessage({ id: "slot6_desc" })}
              modalButton={<img src={playButton} alt="play" />}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default injectIntl(slots)