import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import { graphql, useStaticQuery } from "gatsby"

// Elements
// import Card from "./card"
import Heading from "./heading"
import Modal from "./video_modal"

// Images
import playButton from "../images/play_button.png"

const videoLinks = [
  "qd5oc9hLrXg", //Blackjack
  "v8dqu_aVQk8", //7 Card Hold'em
  "juMyMuf7Cdo", //Baccarat
  "JR5JNVxZolU", //Roulette
  "bOyZbYjUcZg", //Caribbean Stuff
  "fCMEnxSjkYg", //3 Card Mini Poker
]

const Games = ({ intl }) => {
  const data = useStaticQuery(graphql`
    query {
      contentfulEventPost(slug: { eq: "roulette-mania" }) {
        title
        slug
        subtitle
        description
        image {
          fluid(maxWidth: 220, maxHeight: 300) {
            src
          }
        }
      }
    }
  `)
  const post = data.contentfulEventPost
  return (
    <div className="games__wrapper">
      <Heading
        title={intl.formatMessage({ id: "games_heading" })}
        description={intl.formatMessage({ id: "games_description" })}
      />
      <div className="games">
        <div className="games__item games__item1">
          <div className="games__modal">
            <Modal
              videoUrl={videoLinks[0]}
              title={intl.formatMessage({ id: "game1" })}
              description={intl.formatMessage({ id: "game1_desc" })}
              modalButton={<img src={playButton} alt="play" />}
            />
          </div>
        </div>
        <div className="games__item games__item2">
          <div className="games__modal">
            <Modal
              videoUrl={videoLinks[1]}
              title={intl.formatMessage({ id: "game2" })}
              description={intl.formatMessage({ id: "game2_desc" })}
              modalButton={<img src={playButton} alt="play" />}
            />
          </div>
        </div>
        <div className="games__item games__item3">
          <div className="games__modal">
            <Modal
              videoUrl={videoLinks[2]}
              title={intl.formatMessage({ id: "game3" })}
              description={intl.formatMessage({ id: "game3_desc" })}
              modalButton={<img src={playButton} alt="play"/>}
            />
          </div>
        </div>
        <div className="games__item games__item4">
          <div className="games__modal">
            <Modal
              videoUrl={videoLinks[3]}
              title={intl.formatMessage({ id: "game4" })}
              description={intl.formatMessage({ id: "game4_desc" })}
              modalButton={<img src={playButton} alt="play" />}
            />
          </div>
        </div>
        <div className="games__item games__item5">
          <div className="games__modal">
            <Modal
              videoUrl={videoLinks[4]}
              title={intl.formatMessage({ id: "game5" })}
              description={intl.formatMessage({ id: "game5_desc" })}
              modalButton={<img src={playButton} alt="play" />}
            />
          </div>
        </div>
        <div className="games__item games__item6">
          <div className="games__modal">
            <Modal
              videoUrl={videoLinks[5]}
              title={intl.formatMessage({ id: "game6" })}
              description={intl.formatMessage({ id: "game6_desc" })}
              modalButton={<img src={playButton} alt="play" />}
            />
          </div>
        </div>
        {/* <Card
          className="games__item7"
          image={post.image.fluid}
          title={post.title}
          subtitle={post.subtitle}
          description={post.description}
          buttonLink={`/events/${post.slug}`}
        /> */}
      </div>
    </div>
  )
}

export default injectIntl(Games)
