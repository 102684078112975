import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"

// Elements
import Heading from "./heading"

const Fortune = ({ intl }) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "fortune.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <div className="fortune">
      <div className="fortune__content">
        <Heading
          title={intl.formatMessage({ id: "fortune_heading" })}
          description={intl.formatMessage({ id: "fortune_description" })}
        />
        <div className="fortune__contentWrapper">
          <p className="fortune__text">
            {intl.formatMessage({ id: "fortune_text" })}
          </p>
          <h1 className="fortune__goodLuck">
            {intl.formatMessage({ id: "good_luck" })}
          </h1>
        </div>
        <Img
          fluid={data.file.childImageSharp.fluid}
          className="image_desktop"
          style={{ position: "initial", height: "100vh", width: "100vw" }}
        />
      </div>
      <Img
        fluid={data.file.childImageSharp.fluid}
        className="image_mobile"
        objectFit="cover"
        objectPosition="65% 50%"
        style={{ width: "100%", height: "100vh" }}
      />
    </div>
  )
}

export default injectIntl(Fortune)
